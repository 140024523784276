const LP_FIRST_PATH = "/lp/"

export default defineNuxtRouteMiddleware((to) => {
  let urlFirstPath = to.path.match(/(^\/.+?\/)|(^\/.+)/)
  urlFirstPath = urlFirstPath ? urlFirstPath[0] : to.path

  if (urlFirstPath === LP_FIRST_PATH) {
    // LPページは削除されたため、アクセスがあった場合はトップページにリダイレクトさせる
    return navigateTo("/")
  }
})
