<template lang="pug">
main.container
  PageTitleLower(enTitle="404 not found" jaTitle="404 エラー")
  .lower-page-contents
    breadcrumb
    .width-m
      h3.bg-white-bagie
        | 申し訳ありません。お探しのページは見つかりませんでした。
  PartsCtaFooter
</template>

<script setup lang="ts">

import PageTitleLower from "~/components/parts/PageTitleLower.vue"
import Breadcrumb from "~/components/parts/RadiBreadcrumb.vue"

definePageMeta({
  layout: "default",
});
useRadiHead({
  title: "404 エラー",
  description: "株式会社ラジコードの404ページです",
  type: "article",
  url: "https://radicode.co.jp/privacy_policy",
  image: "https://radicode.co.jp/ogp/ogp-tw.png",
  ninjaPageId: "",
  breadcrumbs: {
    data: [
      {
        name: "ホーム",
        path: "/"
      },
      {
        name: "404 エラー",
        path: ""
      }
    ]
  }
})


</script>

<style lang="scss" scoped>
.bg-white-bagie {
  background-color: $white_beige;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

h3 {
  margin: 40px 0 20px;
  line-height: 1.6em;
}
</style>
