import revive_payload_client_GfRBh8v6Kl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.18.4_eslint@8.51.0_sass@1.69.3_stylelint@15.10.3_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_sjLRxzeSYh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.18.4_eslint@8.51.0_sass@1.69.3_stylelint@15.10.3_typescript@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_kPEHq23QZ0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@1.5.4_vue@3.3.4/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_cCcnhkyuHK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.18.4_eslint@8.51.0_sass@1.69.3_stylelint@15.10.3_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import unhead_jsYqqC8mgb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.18.4_eslint@8.51.0_sass@1.69.3_stylelint@15.10.3_typescript@5.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_gmK4HNGWfi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.18.4_eslint@8.51.0_sass@1.69.3_stylelint@15.10.3_typescript@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import defaults_dOtnWoRU5Y from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtseo+module@2.0.0-beta.15_@nuxt+devtools@1.0.0-beta.1_@unhead+vue@1.7.4_axios@0.21.4_nuxt_22kuj5m4pdyc6thjyixf6ecqhq/node_modules/@nuxtseo/module/dist/runtime/plugin/defaults.mjs";
import siteConfig_ojCd8xSlhI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@3.1.5_vue@3.3.4/node_modules/nuxt-seo-experiments/dist/runtime/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_RG1S7b7L90 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@3.1.5_vue@3.3.4/node_modules/nuxt-seo-experiments/dist/runtime/plugins/inferSeoMetaPlugin.mjs";
import plugin_jDlkc8klnK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-schema-org@2.2.0_@unhead+vue@1.7.4/node_modules/nuxt-schema-org/dist/runtime/plugin.mjs";
import plugin_v2vBc5WWLo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-bugsnag@7.0.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_yfLyGOvpmA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.18.4_eslint@8.51.0_sass@1.69.3_stylelint@15.10.3_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fontawesome_client_DEKE6iwpaN from "/opt/buildhome/repo/app/plugins/fontawesome.client.ts";
import social_sharing_XKiAV6Qy28 from "/opt/buildhome/repo/app/plugins/social-sharing.ts";
import vue_gtm_client_lCcpPMh6dK from "/opt/buildhome/repo/app/plugins/vue-gtm.client.ts";
import vue_lazyload_sqVDak6nPf from "/opt/buildhome/repo/app/plugins/vue-lazyload.ts";
import vue_scrollto_AHu9dOXkOO from "/opt/buildhome/repo/app/plugins/vue-scrollto.ts";
export default [
  revive_payload_client_GfRBh8v6Kl,
  router_sjLRxzeSYh,
  _0_siteConfig_kPEHq23QZ0,
  payload_client_cCcnhkyuHK,
  components_plugin_KR1HBZs4kY,
  unhead_jsYqqC8mgb,
  prefetch_client_gmK4HNGWfi,
  defaults_dOtnWoRU5Y,
  siteConfig_ojCd8xSlhI,
  inferSeoMetaPlugin_RG1S7b7L90,
  plugin_jDlkc8klnK,
  plugin_v2vBc5WWLo,
  chunk_reload_client_yfLyGOvpmA,
  fontawesome_client_DEKE6iwpaN,
  social_sharing_XKiAV6Qy28,
  vue_gtm_client_lCcpPMh6dK,
  vue_lazyload_sqVDak6nPf,
  vue_scrollto_AHu9dOXkOO
]