
type BreadcrumbData = { name: string, path?: string }
type Breadcrumbs =
  {
    data: BreadcrumbData[]
  }

type RadiMeta = {
  title: string;
  description: string;
  type: string;
  url: string;
  image: string;
  ninjaPageId: string;
  breadcrumbs?: Breadcrumbs
}

export const pageTitle = ref("")
export const ninjaPageId = ref("")
export const breadcrumbs = ref<Breadcrumbs|undefined>({ data: [{ name: "" }] })

export const useRadiHead = (meta: RadiMeta) => {

  pageTitle.value = meta.title
  ninjaPageId.value = meta.ninjaPageId
  breadcrumbs.value = meta.breadcrumbs

  useHead(() =>
    ({
      title: meta.title,
      titleTemplate: "%s | 株式会社ラジコード",
      meta: [
        {
          hid: "description",
          name: "description",
          content: meta.description
        },
        { hid: "og:type", property: "og:type", content: meta.type },
        { hid: "og:title", property: "og:title", content: meta.title },
        {
          hid: "og:description",
          property: "og:description",
          content: meta.description
        },
        { hid: "og:url", property: "og:url", content: meta.url },
        { hid: "og:image", property: "og:image", content: meta.image }
      ]
    })
  )
}
