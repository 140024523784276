import { default as indexLaHnoF8FmVMeta } from "/opt/buildhome/repo/app/pages/company/index.vue?macro=true";
import { default as indexNoTz95enctMeta } from "/opt/buildhome/repo/app/pages/concept/index.vue?macro=true";
import { default as company_45pdfm1HnbqcR0cMeta } from "/opt/buildhome/repo/app/pages/forms/company-pdf.vue?macro=true";
import { default as contact3JGSQbsLWwMeta } from "/opt/buildhome/repo/app/pages/forms/contact.vue?macro=true";
import { default as contact_thanksUBqKxKjQDSMeta } from "/opt/buildhome/repo/app/pages/forms/contact_thanks.vue?macro=true";
import { default as dl_thanksWBuquljqDzMeta } from "/opt/buildhome/repo/app/pages/forms/dl_thanks.vue?macro=true";
import { default as whitepaper_45pdfENwCeROgeCMeta } from "/opt/buildhome/repo/app/pages/forms/whitepaper-pdf.vue?macro=true";
import { default as index9xeP2sz8ZcMeta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as bunkyoshar36JbrBQvZMeta } from "/opt/buildhome/repo/app/pages/interviews/bunkyosha.vue?macro=true";
import { default as firsthand_45commaiJbfgqWB2Meta } from "/opt/buildhome/repo/app/pages/interviews/firsthand-comm.vue?macro=true";
import { default as indexwTLMt6bjJtMeta } from "/opt/buildhome/repo/app/pages/interviews/index.vue?macro=true";
import { default as jikorikaixRb2sQ7GDIMeta } from "/opt/buildhome/repo/app/pages/interviews/jikorikai.vue?macro=true";
import { default as jikorikai_2zILkkBUIv6Meta } from "/opt/buildhome/repo/app/pages/interviews/jikorikai_2.vue?macro=true";
import { default as jikorikai_3a2U7bDqxVDMeta } from "/opt/buildhome/repo/app/pages/interviews/jikorikai_3.vue?macro=true";
import { default as valuepressPQzfaL7oTfMeta } from "/opt/buildhome/repo/app/pages/interviews/valuepress.vue?macro=true";
import { default as privacy_policybHM6LWj1HLMeta } from "/opt/buildhome/repo/app/pages/privacy_policy.vue?macro=true";
import { default as indexIbGtHqvnUaMeta } from "/opt/buildhome/repo/app/pages/recruit/corevalues/index.vue?macro=true";
import { default as designerxwksOppMOBMeta } from "/opt/buildhome/repo/app/pages/recruit/entry/designer.vue?macro=true";
import { default as engineerLoVcJfZ4xIMeta } from "/opt/buildhome/repo/app/pages/recruit/entry/engineer.vue?macro=true";
import { default as indexbaBT4iKkUJMeta } from "/opt/buildhome/repo/app/pages/recruit/faq/index.vue?macro=true";
import { default as index0VJk4Eg1OnMeta } from "/opt/buildhome/repo/app/pages/recruit/index.vue?macro=true";
import { default as _1e4fZVzhoprMeta } from "/opt/buildhome/repo/app/pages/recruit/interviews/1.vue?macro=true";
import { default as indexmxwqXZQ7e8Meta } from "/opt/buildhome/repo/app/pages/recruit/ourculture/index.vue?macro=true";
import { default as recruitcUEWiGRmV4Meta } from "/opt/buildhome/repo/app/pages/recruit.vue?macro=true";
import { default as care_45facility_45video_45call_45system_45developmenteR4Hoy0bn3Meta } from "/opt/buildhome/repo/app/pages/service/casestudies/care-and-welfare/care-facility-video-call-system-development.vue?macro=true";
import { default as chatgpt_45powered_45learning_45support_45tool_45development7a1Rmnk45QMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/education/chatgpt-powered-learning-support-tool-development.vue?macro=true";
import { default as coaching_45serviceqMFzzuSWQGMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/education/coaching-service.vue?macro=true";
import { default as learning_45data_45analysis_45platform_45developmenthEWqhupVr8Meta } from "/opt/buildhome/repo/app/pages/service/casestudies/education/learning-data-analysis-platform-development.vue?macro=true";
import { default as indexP00X1VrFjdMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/index.vue?macro=true";
import { default as chatgpt_45content_45generation_45assistant_45development8XsRMQeCiDMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/media/chatgpt-content-generation-assistant-development.vue?macro=true";
import { default as improved_45website_45display_45speedVtdeavZfrDMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/media/improved-website-display-speed.vue?macro=true";
import { default as chatgpt_45market_45analysis_45system_45developmentiYCJWXAwzfMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/publication/chatgpt-market-analysis-system-development.vue?macro=true";
import { default as customer_45purchasing_45trend_45analysis_45systemJf9vMYBqLrMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/publication/customer-purchasing-trend-analysis-system.vue?macro=true";
import { default as development_45of_45overseas_45trading_45system_45for_45domestic_45copyrights_45with_45multilingual_45supporttWr5YbpWl6Meta } from "/opt/buildhome/repo/app/pages/service/casestudies/publication/development-of-overseas-trading-system-for-domestic-copyrights-with-multilingual-support.vue?macro=true";
import { default as sales_45management_45integrated_45pos_45system_45developmentGtFeJwDhHqMeta } from "/opt/buildhome/repo/app/pages/service/casestudies/publication/sales-management-integrated-pos-system-development.vue?macro=true";
import { default as indexN07QUiisuvMeta } from "/opt/buildhome/repo/app/pages/service/index.vue?macro=true";
import { default as planrwFrZ6JCjgMeta } from "/opt/buildhome/repo/app/pages/service/plan.vue?macro=true";
import { default as chooseofbflZnw0QMeta } from "/opt/buildhome/repo/app/pages/subscription/choose.vue?macro=true";
import { default as faqneMNQKJkUrMeta } from "/opt/buildhome/repo/app/pages/subscription/faq.vue?macro=true";
import { default as flowB0NGKX7wYQMeta } from "/opt/buildhome/repo/app/pages/subscription/flow.vue?macro=true";
import { default as indexGDewbQ7zXFMeta } from "/opt/buildhome/repo/app/pages/subscription/index.vue?macro=true";
import { default as reasonZxdT1tOKuxMeta } from "/opt/buildhome/repo/app/pages/subscription/reason.vue?macro=true";
import { default as indexQAp7AspEl2Meta } from "/opt/buildhome/repo/app/pages/wordbook/index.vue?macro=true";
import { default as agile_developmentfCyg70uLCVMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/agile_development.vue?macro=true";
import { default as contracted_developmentDlMm5tRFUYMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/contracted_development.vue?macro=true";
import { default as inception_deckgpwlzz9mrxMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/inception_deck.vue?macro=true";
import { default as incidentjQuNAZmZ1wMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/incident.vue?macro=true";
import { default as lean_canvasVBsLkYwitZMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/lean_canvas.vue?macro=true";
import { default as mvpqRWBQMIJUxMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/mvp.vue?macro=true";
import { default as pivotjeveph8pUDMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/pivot.vue?macro=true";
import { default as product_managementYvHE9EBLZnMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/product_management.vue?macro=true";
import { default as prototypeY5kRNa4by0Meta } from "/opt/buildhome/repo/app/pages/wordbook/words/prototype.vue?macro=true";
import { default as ragile_developmentbaD6A9WaaBMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/ragile_development.vue?macro=true";
import { default as releaseMav9Uyu4rkMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/release.vue?macro=true";
import { default as reviewjK24RSShWrMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/review.vue?macro=true";
import { default as risk_hedgenDc2a9htlzMeta } from "/opt/buildhome/repo/app/pages/wordbook/words/risk_hedge.vue?macro=true";
import { default as wordbookuHWufSz3dlMeta } from "/opt/buildhome/repo/app/pages/wordbook.vue?macro=true";
import { default as bookscanxbbh5CfD0IMeta } from "/opt/buildhome/repo/app/pages/works/bookscan.vue?macro=true";
import { default as customerplus3UcMhIq7ZKMeta } from "/opt/buildhome/repo/app/pages/works/customerplus.vue?macro=true";
import { default as indexKcncjNDKShMeta } from "/opt/buildhome/repo/app/pages/works/index.vue?macro=true";
import { default as jikorikaiyee1XVFtXsMeta } from "/opt/buildhome/repo/app/pages/works/jikorikai.vue?macro=true";
import { default as unkogakuenAkBv78UZQgMeta } from "/opt/buildhome/repo/app/pages/works/unkogakuen.vue?macro=true";
import { default as unkozemi2rtrvlAUnrMeta } from "/opt/buildhome/repo/app/pages/works/unkozemi.vue?macro=true";
import { default as valuepressmrR77aHMo3Meta } from "/opt/buildhome/repo/app/pages/works/valuepress.vue?macro=true";
export default [
  {
    name: indexLaHnoF8FmVMeta?.name ?? "company",
    path: indexLaHnoF8FmVMeta?.path ?? "/company",
    meta: indexLaHnoF8FmVMeta || {},
    alias: indexLaHnoF8FmVMeta?.alias || [],
    redirect: indexLaHnoF8FmVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexNoTz95enctMeta?.name ?? "concept",
    path: indexNoTz95enctMeta?.path ?? "/concept",
    meta: indexNoTz95enctMeta || {},
    alias: indexNoTz95enctMeta?.alias || [],
    redirect: indexNoTz95enctMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/concept/index.vue").then(m => m.default || m)
  },
  {
    name: company_45pdfm1HnbqcR0cMeta?.name ?? "forms-company-pdf",
    path: company_45pdfm1HnbqcR0cMeta?.path ?? "/forms/company-pdf",
    meta: company_45pdfm1HnbqcR0cMeta || {},
    alias: company_45pdfm1HnbqcR0cMeta?.alias || [],
    redirect: company_45pdfm1HnbqcR0cMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/forms/company-pdf.vue").then(m => m.default || m)
  },
  {
    name: contact3JGSQbsLWwMeta?.name ?? "forms-contact",
    path: contact3JGSQbsLWwMeta?.path ?? "/forms/contact",
    meta: contact3JGSQbsLWwMeta || {},
    alias: contact3JGSQbsLWwMeta?.alias || [],
    redirect: contact3JGSQbsLWwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/forms/contact.vue").then(m => m.default || m)
  },
  {
    name: contact_thanksUBqKxKjQDSMeta?.name ?? "forms-contact_thanks",
    path: contact_thanksUBqKxKjQDSMeta?.path ?? "/forms/contact_thanks",
    meta: contact_thanksUBqKxKjQDSMeta || {},
    alias: contact_thanksUBqKxKjQDSMeta?.alias || [],
    redirect: contact_thanksUBqKxKjQDSMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/forms/contact_thanks.vue").then(m => m.default || m)
  },
  {
    name: dl_thanksWBuquljqDzMeta?.name ?? "forms-dl_thanks",
    path: dl_thanksWBuquljqDzMeta?.path ?? "/forms/dl_thanks",
    meta: dl_thanksWBuquljqDzMeta || {},
    alias: dl_thanksWBuquljqDzMeta?.alias || [],
    redirect: dl_thanksWBuquljqDzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/forms/dl_thanks.vue").then(m => m.default || m)
  },
  {
    name: whitepaper_45pdfENwCeROgeCMeta?.name ?? "forms-whitepaper-pdf",
    path: whitepaper_45pdfENwCeROgeCMeta?.path ?? "/forms/whitepaper-pdf",
    meta: whitepaper_45pdfENwCeROgeCMeta || {},
    alias: whitepaper_45pdfENwCeROgeCMeta?.alias || [],
    redirect: whitepaper_45pdfENwCeROgeCMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/forms/whitepaper-pdf.vue").then(m => m.default || m)
  },
  {
    name: index9xeP2sz8ZcMeta?.name ?? "index",
    path: index9xeP2sz8ZcMeta?.path ?? "/",
    meta: index9xeP2sz8ZcMeta || {},
    alias: index9xeP2sz8ZcMeta?.alias || [],
    redirect: index9xeP2sz8ZcMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: bunkyoshar36JbrBQvZMeta?.name ?? "interviews-bunkyosha",
    path: bunkyoshar36JbrBQvZMeta?.path ?? "/interviews/bunkyosha",
    meta: bunkyoshar36JbrBQvZMeta || {},
    alias: bunkyoshar36JbrBQvZMeta?.alias || [],
    redirect: bunkyoshar36JbrBQvZMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/bunkyosha.vue").then(m => m.default || m)
  },
  {
    name: firsthand_45commaiJbfgqWB2Meta?.name ?? "interviews-firsthand-comm",
    path: firsthand_45commaiJbfgqWB2Meta?.path ?? "/interviews/firsthand-comm",
    meta: firsthand_45commaiJbfgqWB2Meta || {},
    alias: firsthand_45commaiJbfgqWB2Meta?.alias || [],
    redirect: firsthand_45commaiJbfgqWB2Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/firsthand-comm.vue").then(m => m.default || m)
  },
  {
    name: indexwTLMt6bjJtMeta?.name ?? "interviews",
    path: indexwTLMt6bjJtMeta?.path ?? "/interviews",
    meta: indexwTLMt6bjJtMeta || {},
    alias: indexwTLMt6bjJtMeta?.alias || [],
    redirect: indexwTLMt6bjJtMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/index.vue").then(m => m.default || m)
  },
  {
    name: jikorikaixRb2sQ7GDIMeta?.name ?? "interviews-jikorikai",
    path: jikorikaixRb2sQ7GDIMeta?.path ?? "/interviews/jikorikai",
    meta: jikorikaixRb2sQ7GDIMeta || {},
    alias: jikorikaixRb2sQ7GDIMeta?.alias || [],
    redirect: jikorikaixRb2sQ7GDIMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/jikorikai.vue").then(m => m.default || m)
  },
  {
    name: jikorikai_2zILkkBUIv6Meta?.name ?? "interviews-jikorikai_2",
    path: jikorikai_2zILkkBUIv6Meta?.path ?? "/interviews/jikorikai_2",
    meta: jikorikai_2zILkkBUIv6Meta || {},
    alias: jikorikai_2zILkkBUIv6Meta?.alias || [],
    redirect: jikorikai_2zILkkBUIv6Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/jikorikai_2.vue").then(m => m.default || m)
  },
  {
    name: jikorikai_3a2U7bDqxVDMeta?.name ?? "interviews-jikorikai_3",
    path: jikorikai_3a2U7bDqxVDMeta?.path ?? "/interviews/jikorikai_3",
    meta: jikorikai_3a2U7bDqxVDMeta || {},
    alias: jikorikai_3a2U7bDqxVDMeta?.alias || [],
    redirect: jikorikai_3a2U7bDqxVDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/jikorikai_3.vue").then(m => m.default || m)
  },
  {
    name: valuepressPQzfaL7oTfMeta?.name ?? "interviews-valuepress",
    path: valuepressPQzfaL7oTfMeta?.path ?? "/interviews/valuepress",
    meta: valuepressPQzfaL7oTfMeta || {},
    alias: valuepressPQzfaL7oTfMeta?.alias || [],
    redirect: valuepressPQzfaL7oTfMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/interviews/valuepress.vue").then(m => m.default || m)
  },
  {
    name: privacy_policybHM6LWj1HLMeta?.name ?? "privacy_policy",
    path: privacy_policybHM6LWj1HLMeta?.path ?? "/privacy_policy",
    meta: privacy_policybHM6LWj1HLMeta || {},
    alias: privacy_policybHM6LWj1HLMeta?.alias || [],
    redirect: privacy_policybHM6LWj1HLMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/privacy_policy.vue").then(m => m.default || m)
  },
  {
    path: recruitcUEWiGRmV4Meta?.path ?? "/recruit",
    children: [
  {
    name: indexIbGtHqvnUaMeta?.name ?? "recruit-corevalues",
    path: indexIbGtHqvnUaMeta?.path ?? "corevalues",
    meta: indexIbGtHqvnUaMeta || {},
    alias: indexIbGtHqvnUaMeta?.alias || [],
    redirect: indexIbGtHqvnUaMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/corevalues/index.vue").then(m => m.default || m)
  },
  {
    name: designerxwksOppMOBMeta?.name ?? "recruit-entry-designer",
    path: designerxwksOppMOBMeta?.path ?? "entry/designer",
    meta: designerxwksOppMOBMeta || {},
    alias: designerxwksOppMOBMeta?.alias || [],
    redirect: designerxwksOppMOBMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/entry/designer.vue").then(m => m.default || m)
  },
  {
    name: engineerLoVcJfZ4xIMeta?.name ?? "recruit-entry-engineer",
    path: engineerLoVcJfZ4xIMeta?.path ?? "entry/engineer",
    meta: engineerLoVcJfZ4xIMeta || {},
    alias: engineerLoVcJfZ4xIMeta?.alias || [],
    redirect: engineerLoVcJfZ4xIMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/entry/engineer.vue").then(m => m.default || m)
  },
  {
    name: indexbaBT4iKkUJMeta?.name ?? "recruit-faq",
    path: indexbaBT4iKkUJMeta?.path ?? "faq",
    meta: indexbaBT4iKkUJMeta || {},
    alias: indexbaBT4iKkUJMeta?.alias || [],
    redirect: indexbaBT4iKkUJMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index0VJk4Eg1OnMeta?.name ?? "recruit",
    path: index0VJk4Eg1OnMeta?.path ?? "",
    meta: index0VJk4Eg1OnMeta || {},
    alias: index0VJk4Eg1OnMeta?.alias || [],
    redirect: index0VJk4Eg1OnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/index.vue").then(m => m.default || m)
  },
  {
    name: _1e4fZVzhoprMeta?.name ?? "recruit-interviews-1",
    path: _1e4fZVzhoprMeta?.path ?? "interviews/1",
    meta: _1e4fZVzhoprMeta || {},
    alias: _1e4fZVzhoprMeta?.alias || [],
    redirect: _1e4fZVzhoprMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/interviews/1.vue").then(m => m.default || m)
  },
  {
    name: indexmxwqXZQ7e8Meta?.name ?? "recruit-ourculture",
    path: indexmxwqXZQ7e8Meta?.path ?? "ourculture",
    meta: indexmxwqXZQ7e8Meta || {},
    alias: indexmxwqXZQ7e8Meta?.alias || [],
    redirect: indexmxwqXZQ7e8Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit/ourculture/index.vue").then(m => m.default || m)
  }
],
    name: recruitcUEWiGRmV4Meta?.name ?? undefined,
    meta: recruitcUEWiGRmV4Meta || {},
    alias: recruitcUEWiGRmV4Meta?.alias || [],
    redirect: recruitcUEWiGRmV4Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: care_45facility_45video_45call_45system_45developmenteR4Hoy0bn3Meta?.name ?? "service-casestudies-care-and-welfare-care-facility-video-call-system-development",
    path: care_45facility_45video_45call_45system_45developmenteR4Hoy0bn3Meta?.path ?? "/service/casestudies/care-and-welfare/care-facility-video-call-system-development",
    meta: care_45facility_45video_45call_45system_45developmenteR4Hoy0bn3Meta || {},
    alias: care_45facility_45video_45call_45system_45developmenteR4Hoy0bn3Meta?.alias || [],
    redirect: care_45facility_45video_45call_45system_45developmenteR4Hoy0bn3Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/care-and-welfare/care-facility-video-call-system-development.vue").then(m => m.default || m)
  },
  {
    name: chatgpt_45powered_45learning_45support_45tool_45development7a1Rmnk45QMeta?.name ?? "service-casestudies-education-chatgpt-powered-learning-support-tool-development",
    path: chatgpt_45powered_45learning_45support_45tool_45development7a1Rmnk45QMeta?.path ?? "/service/casestudies/education/chatgpt-powered-learning-support-tool-development",
    meta: chatgpt_45powered_45learning_45support_45tool_45development7a1Rmnk45QMeta || {},
    alias: chatgpt_45powered_45learning_45support_45tool_45development7a1Rmnk45QMeta?.alias || [],
    redirect: chatgpt_45powered_45learning_45support_45tool_45development7a1Rmnk45QMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/education/chatgpt-powered-learning-support-tool-development.vue").then(m => m.default || m)
  },
  {
    name: coaching_45serviceqMFzzuSWQGMeta?.name ?? "service-casestudies-education-coaching-service",
    path: coaching_45serviceqMFzzuSWQGMeta?.path ?? "/service/casestudies/education/coaching-service",
    meta: coaching_45serviceqMFzzuSWQGMeta || {},
    alias: coaching_45serviceqMFzzuSWQGMeta?.alias || [],
    redirect: coaching_45serviceqMFzzuSWQGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/education/coaching-service.vue").then(m => m.default || m)
  },
  {
    name: learning_45data_45analysis_45platform_45developmenthEWqhupVr8Meta?.name ?? "service-casestudies-education-learning-data-analysis-platform-development",
    path: learning_45data_45analysis_45platform_45developmenthEWqhupVr8Meta?.path ?? "/service/casestudies/education/learning-data-analysis-platform-development",
    meta: learning_45data_45analysis_45platform_45developmenthEWqhupVr8Meta || {},
    alias: learning_45data_45analysis_45platform_45developmenthEWqhupVr8Meta?.alias || [],
    redirect: learning_45data_45analysis_45platform_45developmenthEWqhupVr8Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/education/learning-data-analysis-platform-development.vue").then(m => m.default || m)
  },
  {
    name: indexP00X1VrFjdMeta?.name ?? "service-casestudies",
    path: indexP00X1VrFjdMeta?.path ?? "/service/casestudies",
    meta: indexP00X1VrFjdMeta || {},
    alias: indexP00X1VrFjdMeta?.alias || [],
    redirect: indexP00X1VrFjdMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/index.vue").then(m => m.default || m)
  },
  {
    name: chatgpt_45content_45generation_45assistant_45development8XsRMQeCiDMeta?.name ?? "service-casestudies-media-chatgpt-content-generation-assistant-development",
    path: chatgpt_45content_45generation_45assistant_45development8XsRMQeCiDMeta?.path ?? "/service/casestudies/media/chatgpt-content-generation-assistant-development",
    meta: chatgpt_45content_45generation_45assistant_45development8XsRMQeCiDMeta || {},
    alias: chatgpt_45content_45generation_45assistant_45development8XsRMQeCiDMeta?.alias || [],
    redirect: chatgpt_45content_45generation_45assistant_45development8XsRMQeCiDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/media/chatgpt-content-generation-assistant-development.vue").then(m => m.default || m)
  },
  {
    name: improved_45website_45display_45speedVtdeavZfrDMeta?.name ?? "service-casestudies-media-improved-website-display-speed",
    path: improved_45website_45display_45speedVtdeavZfrDMeta?.path ?? "/service/casestudies/media/improved-website-display-speed",
    meta: improved_45website_45display_45speedVtdeavZfrDMeta || {},
    alias: improved_45website_45display_45speedVtdeavZfrDMeta?.alias || [],
    redirect: improved_45website_45display_45speedVtdeavZfrDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/media/improved-website-display-speed.vue").then(m => m.default || m)
  },
  {
    name: chatgpt_45market_45analysis_45system_45developmentiYCJWXAwzfMeta?.name ?? "service-casestudies-publication-chatgpt-market-analysis-system-development",
    path: chatgpt_45market_45analysis_45system_45developmentiYCJWXAwzfMeta?.path ?? "/service/casestudies/publication/chatgpt-market-analysis-system-development",
    meta: chatgpt_45market_45analysis_45system_45developmentiYCJWXAwzfMeta || {},
    alias: chatgpt_45market_45analysis_45system_45developmentiYCJWXAwzfMeta?.alias || [],
    redirect: chatgpt_45market_45analysis_45system_45developmentiYCJWXAwzfMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/publication/chatgpt-market-analysis-system-development.vue").then(m => m.default || m)
  },
  {
    name: customer_45purchasing_45trend_45analysis_45systemJf9vMYBqLrMeta?.name ?? "service-casestudies-publication-customer-purchasing-trend-analysis-system",
    path: customer_45purchasing_45trend_45analysis_45systemJf9vMYBqLrMeta?.path ?? "/service/casestudies/publication/customer-purchasing-trend-analysis-system",
    meta: customer_45purchasing_45trend_45analysis_45systemJf9vMYBqLrMeta || {},
    alias: customer_45purchasing_45trend_45analysis_45systemJf9vMYBqLrMeta?.alias || [],
    redirect: customer_45purchasing_45trend_45analysis_45systemJf9vMYBqLrMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/publication/customer-purchasing-trend-analysis-system.vue").then(m => m.default || m)
  },
  {
    name: development_45of_45overseas_45trading_45system_45for_45domestic_45copyrights_45with_45multilingual_45supporttWr5YbpWl6Meta?.name ?? "service-casestudies-publication-development-of-overseas-trading-system-for-domestic-copyrights-with-multilingual-support",
    path: development_45of_45overseas_45trading_45system_45for_45domestic_45copyrights_45with_45multilingual_45supporttWr5YbpWl6Meta?.path ?? "/service/casestudies/publication/development-of-overseas-trading-system-for-domestic-copyrights-with-multilingual-support",
    meta: development_45of_45overseas_45trading_45system_45for_45domestic_45copyrights_45with_45multilingual_45supporttWr5YbpWl6Meta || {},
    alias: development_45of_45overseas_45trading_45system_45for_45domestic_45copyrights_45with_45multilingual_45supporttWr5YbpWl6Meta?.alias || [],
    redirect: development_45of_45overseas_45trading_45system_45for_45domestic_45copyrights_45with_45multilingual_45supporttWr5YbpWl6Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/publication/development-of-overseas-trading-system-for-domestic-copyrights-with-multilingual-support.vue").then(m => m.default || m)
  },
  {
    name: sales_45management_45integrated_45pos_45system_45developmentGtFeJwDhHqMeta?.name ?? "service-casestudies-publication-sales-management-integrated-pos-system-development",
    path: sales_45management_45integrated_45pos_45system_45developmentGtFeJwDhHqMeta?.path ?? "/service/casestudies/publication/sales-management-integrated-pos-system-development",
    meta: sales_45management_45integrated_45pos_45system_45developmentGtFeJwDhHqMeta || {},
    alias: sales_45management_45integrated_45pos_45system_45developmentGtFeJwDhHqMeta?.alias || [],
    redirect: sales_45management_45integrated_45pos_45system_45developmentGtFeJwDhHqMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/casestudies/publication/sales-management-integrated-pos-system-development.vue").then(m => m.default || m)
  },
  {
    name: indexN07QUiisuvMeta?.name ?? "service",
    path: indexN07QUiisuvMeta?.path ?? "/service",
    meta: indexN07QUiisuvMeta || {},
    alias: indexN07QUiisuvMeta?.alias || [],
    redirect: indexN07QUiisuvMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: planrwFrZ6JCjgMeta?.name ?? "service-plan",
    path: planrwFrZ6JCjgMeta?.path ?? "/service/plan",
    meta: planrwFrZ6JCjgMeta || {},
    alias: planrwFrZ6JCjgMeta?.alias || [],
    redirect: planrwFrZ6JCjgMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/service/plan.vue").then(m => m.default || m)
  },
  {
    name: chooseofbflZnw0QMeta?.name ?? "subscription-choose",
    path: chooseofbflZnw0QMeta?.path ?? "/subscription/choose",
    meta: chooseofbflZnw0QMeta || {},
    alias: chooseofbflZnw0QMeta?.alias || [],
    redirect: chooseofbflZnw0QMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/subscription/choose.vue").then(m => m.default || m)
  },
  {
    name: faqneMNQKJkUrMeta?.name ?? "subscription-faq",
    path: faqneMNQKJkUrMeta?.path ?? "/subscription/faq",
    meta: faqneMNQKJkUrMeta || {},
    alias: faqneMNQKJkUrMeta?.alias || [],
    redirect: faqneMNQKJkUrMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/subscription/faq.vue").then(m => m.default || m)
  },
  {
    name: flowB0NGKX7wYQMeta?.name ?? "subscription-flow",
    path: flowB0NGKX7wYQMeta?.path ?? "/subscription/flow",
    meta: flowB0NGKX7wYQMeta || {},
    alias: flowB0NGKX7wYQMeta?.alias || [],
    redirect: flowB0NGKX7wYQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/subscription/flow.vue").then(m => m.default || m)
  },
  {
    name: indexGDewbQ7zXFMeta?.name ?? "subscription",
    path: indexGDewbQ7zXFMeta?.path ?? "/subscription",
    meta: indexGDewbQ7zXFMeta || {},
    alias: indexGDewbQ7zXFMeta?.alias || [],
    redirect: indexGDewbQ7zXFMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: reasonZxdT1tOKuxMeta?.name ?? "subscription-reason",
    path: reasonZxdT1tOKuxMeta?.path ?? "/subscription/reason",
    meta: reasonZxdT1tOKuxMeta || {},
    alias: reasonZxdT1tOKuxMeta?.alias || [],
    redirect: reasonZxdT1tOKuxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/subscription/reason.vue").then(m => m.default || m)
  },
  {
    path: wordbookuHWufSz3dlMeta?.path ?? "/wordbook",
    children: [
  {
    name: indexQAp7AspEl2Meta?.name ?? "wordbook",
    path: indexQAp7AspEl2Meta?.path ?? "",
    meta: indexQAp7AspEl2Meta || {},
    alias: indexQAp7AspEl2Meta?.alias || [],
    redirect: indexQAp7AspEl2Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/index.vue").then(m => m.default || m)
  },
  {
    name: agile_developmentfCyg70uLCVMeta?.name ?? "wordbook-words-agile_development",
    path: agile_developmentfCyg70uLCVMeta?.path ?? "words/agile_development",
    meta: agile_developmentfCyg70uLCVMeta || {},
    alias: agile_developmentfCyg70uLCVMeta?.alias || [],
    redirect: agile_developmentfCyg70uLCVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/agile_development.vue").then(m => m.default || m)
  },
  {
    name: contracted_developmentDlMm5tRFUYMeta?.name ?? "wordbook-words-contracted_development",
    path: contracted_developmentDlMm5tRFUYMeta?.path ?? "words/contracted_development",
    meta: contracted_developmentDlMm5tRFUYMeta || {},
    alias: contracted_developmentDlMm5tRFUYMeta?.alias || [],
    redirect: contracted_developmentDlMm5tRFUYMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/contracted_development.vue").then(m => m.default || m)
  },
  {
    name: inception_deckgpwlzz9mrxMeta?.name ?? "wordbook-words-inception_deck",
    path: inception_deckgpwlzz9mrxMeta?.path ?? "words/inception_deck",
    meta: inception_deckgpwlzz9mrxMeta || {},
    alias: inception_deckgpwlzz9mrxMeta?.alias || [],
    redirect: inception_deckgpwlzz9mrxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/inception_deck.vue").then(m => m.default || m)
  },
  {
    name: incidentjQuNAZmZ1wMeta?.name ?? "wordbook-words-incident",
    path: incidentjQuNAZmZ1wMeta?.path ?? "words/incident",
    meta: incidentjQuNAZmZ1wMeta || {},
    alias: incidentjQuNAZmZ1wMeta?.alias || [],
    redirect: incidentjQuNAZmZ1wMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/incident.vue").then(m => m.default || m)
  },
  {
    name: lean_canvasVBsLkYwitZMeta?.name ?? "wordbook-words-lean_canvas",
    path: lean_canvasVBsLkYwitZMeta?.path ?? "words/lean_canvas",
    meta: lean_canvasVBsLkYwitZMeta || {},
    alias: lean_canvasVBsLkYwitZMeta?.alias || [],
    redirect: lean_canvasVBsLkYwitZMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/lean_canvas.vue").then(m => m.default || m)
  },
  {
    name: mvpqRWBQMIJUxMeta?.name ?? "wordbook-words-mvp",
    path: mvpqRWBQMIJUxMeta?.path ?? "words/mvp",
    meta: mvpqRWBQMIJUxMeta || {},
    alias: mvpqRWBQMIJUxMeta?.alias || [],
    redirect: mvpqRWBQMIJUxMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/mvp.vue").then(m => m.default || m)
  },
  {
    name: pivotjeveph8pUDMeta?.name ?? "wordbook-words-pivot",
    path: pivotjeveph8pUDMeta?.path ?? "words/pivot",
    meta: pivotjeveph8pUDMeta || {},
    alias: pivotjeveph8pUDMeta?.alias || [],
    redirect: pivotjeveph8pUDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/pivot.vue").then(m => m.default || m)
  },
  {
    name: product_managementYvHE9EBLZnMeta?.name ?? "wordbook-words-product_management",
    path: product_managementYvHE9EBLZnMeta?.path ?? "words/product_management",
    meta: product_managementYvHE9EBLZnMeta || {},
    alias: product_managementYvHE9EBLZnMeta?.alias || [],
    redirect: product_managementYvHE9EBLZnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/product_management.vue").then(m => m.default || m)
  },
  {
    name: prototypeY5kRNa4by0Meta?.name ?? "wordbook-words-prototype",
    path: prototypeY5kRNa4by0Meta?.path ?? "words/prototype",
    meta: prototypeY5kRNa4by0Meta || {},
    alias: prototypeY5kRNa4by0Meta?.alias || [],
    redirect: prototypeY5kRNa4by0Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/prototype.vue").then(m => m.default || m)
  },
  {
    name: ragile_developmentbaD6A9WaaBMeta?.name ?? "wordbook-words-ragile_development",
    path: ragile_developmentbaD6A9WaaBMeta?.path ?? "words/ragile_development",
    meta: ragile_developmentbaD6A9WaaBMeta || {},
    alias: ragile_developmentbaD6A9WaaBMeta?.alias || [],
    redirect: ragile_developmentbaD6A9WaaBMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/ragile_development.vue").then(m => m.default || m)
  },
  {
    name: releaseMav9Uyu4rkMeta?.name ?? "wordbook-words-release",
    path: releaseMav9Uyu4rkMeta?.path ?? "words/release",
    meta: releaseMav9Uyu4rkMeta || {},
    alias: releaseMav9Uyu4rkMeta?.alias || [],
    redirect: releaseMav9Uyu4rkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/release.vue").then(m => m.default || m)
  },
  {
    name: reviewjK24RSShWrMeta?.name ?? "wordbook-words-review",
    path: reviewjK24RSShWrMeta?.path ?? "words/review",
    meta: reviewjK24RSShWrMeta || {},
    alias: reviewjK24RSShWrMeta?.alias || [],
    redirect: reviewjK24RSShWrMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/review.vue").then(m => m.default || m)
  },
  {
    name: risk_hedgenDc2a9htlzMeta?.name ?? "wordbook-words-risk_hedge",
    path: risk_hedgenDc2a9htlzMeta?.path ?? "words/risk_hedge",
    meta: risk_hedgenDc2a9htlzMeta || {},
    alias: risk_hedgenDc2a9htlzMeta?.alias || [],
    redirect: risk_hedgenDc2a9htlzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook/words/risk_hedge.vue").then(m => m.default || m)
  }
],
    name: wordbookuHWufSz3dlMeta?.name ?? undefined,
    meta: wordbookuHWufSz3dlMeta || {},
    alias: wordbookuHWufSz3dlMeta?.alias || [],
    redirect: wordbookuHWufSz3dlMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/wordbook.vue").then(m => m.default || m)
  },
  {
    name: bookscanxbbh5CfD0IMeta?.name ?? "works-bookscan",
    path: bookscanxbbh5CfD0IMeta?.path ?? "/works/bookscan",
    meta: bookscanxbbh5CfD0IMeta || {},
    alias: bookscanxbbh5CfD0IMeta?.alias || [],
    redirect: bookscanxbbh5CfD0IMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/bookscan.vue").then(m => m.default || m)
  },
  {
    name: customerplus3UcMhIq7ZKMeta?.name ?? "works-customerplus",
    path: customerplus3UcMhIq7ZKMeta?.path ?? "/works/customerplus",
    meta: customerplus3UcMhIq7ZKMeta || {},
    alias: customerplus3UcMhIq7ZKMeta?.alias || [],
    redirect: customerplus3UcMhIq7ZKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/customerplus.vue").then(m => m.default || m)
  },
  {
    name: indexKcncjNDKShMeta?.name ?? "works",
    path: indexKcncjNDKShMeta?.path ?? "/works",
    meta: indexKcncjNDKShMeta || {},
    alias: indexKcncjNDKShMeta?.alias || [],
    redirect: indexKcncjNDKShMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/index.vue").then(m => m.default || m)
  },
  {
    name: jikorikaiyee1XVFtXsMeta?.name ?? "works-jikorikai",
    path: jikorikaiyee1XVFtXsMeta?.path ?? "/works/jikorikai",
    meta: jikorikaiyee1XVFtXsMeta || {},
    alias: jikorikaiyee1XVFtXsMeta?.alias || [],
    redirect: jikorikaiyee1XVFtXsMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/jikorikai.vue").then(m => m.default || m)
  },
  {
    name: unkogakuenAkBv78UZQgMeta?.name ?? "works-unkogakuen",
    path: unkogakuenAkBv78UZQgMeta?.path ?? "/works/unkogakuen",
    meta: unkogakuenAkBv78UZQgMeta || {},
    alias: unkogakuenAkBv78UZQgMeta?.alias || [],
    redirect: unkogakuenAkBv78UZQgMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/unkogakuen.vue").then(m => m.default || m)
  },
  {
    name: unkozemi2rtrvlAUnrMeta?.name ?? "works-unkozemi",
    path: unkozemi2rtrvlAUnrMeta?.path ?? "/works/unkozemi",
    meta: unkozemi2rtrvlAUnrMeta || {},
    alias: unkozemi2rtrvlAUnrMeta?.alias || [],
    redirect: unkozemi2rtrvlAUnrMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/unkozemi.vue").then(m => m.default || m)
  },
  {
    name: valuepressmrR77aHMo3Meta?.name ?? "works-valuepress",
    path: valuepressmrR77aHMo3Meta?.path ?? "/works/valuepress",
    meta: valuepressmrR77aHMo3Meta || {},
    alias: valuepressmrR77aHMo3Meta?.alias || [],
    redirect: valuepressmrR77aHMo3Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/app/pages/works/valuepress.vue").then(m => m.default || m)
  }
]