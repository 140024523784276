import { library, config } from "@fortawesome/fontawesome-svg-core"
// eslint-disable-next-line import/named
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faCog, faEnvelope, faLaughSquint, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { faFacebookSquare, faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

config.autoAddCss = false

// regular/blandsを使いたい場合は別途インストールする
library.add(faCog)
library.add(faEnvelope)
library.add(faLaughSquint)
library.add(faArrowRight)
library.add(faFacebookSquare)
library.add(faFacebookF)
library.add(faTwitter)
library.add(faInstagram)

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon)
})
